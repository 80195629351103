import React, { useRef } from 'react';
import { ReactTypeformEmbed } from 'react-typeform-embed';
import { Block, Description, Hero, Layout, Title, Half, Third, Button } from '@dragonchain-dev/dragon-kit';
import { Shop } from '@dragonchain-dev/dragon-kit-icons';
import { Layout as PageLayout, SEO } from '../components/_index';

const { IMAGES_URI } = process.env;
const { ACCOUNT_URI } = process.env;
const cloudsImage = `${IMAGES_URI}/clients/beaxy/clouds-blue@2x.jpg`;
const cloudsDarkImage = `${IMAGES_URI}/texture/clouds-blue-dark@2x.jpg`;
const halfCloudStyles = { backgroundImage: `url(${cloudsImage})` };
const halfCloudDarkStyles = { backgroundImage: `url(${cloudsDarkImage})` };

const OpenSourceMoney = () => {
  const contactForm = useRef();

  return (
    <>
      <PageLayout>
        <SEO
          title="Dragonchain, The American Blockchain Company"
          description="A new episode of Open Source Money broadcasts on Discovery Science channel each Saturday."
          image={`${IMAGES_URI}/banners/open-source-money.png`}
          cardType="summary_large_image"
        />
        <Layout dark background="blue-dark-fade">
          <Hero type="code" video={{ url: 'https://youtu.be/ngUyMw1pETE', autoPlay: true }}>
            <Title color="white">Dragonchain, The American Blockchain Company</Title>
            <Description color="blue-light">
              <p>
                Create scalable and secure blockchain applications today and see the entire Open Source Money series now!
              </p>
            </Description>
          </Hero>

          <Block type="thirds">
            <Third
              photo={`${IMAGES_URI}/illustrations/call-to-action-consulting-tall@2x.png`}
              photoSize="small"
            >
              <Title tag="h3">For Business</Title>
              <Description>
                From social media reinvented to new ways of dealing with our digital identities, discover our
                customizable blockchain solutions.
              </Description>
              <Button type="button" onClick={() => contactForm.current.typeform.open()}>
                Learn More
              </Button>
            </Third>
            <Third
              photo={`${IMAGES_URI}/illustrations/call-to-action-developers-tall@2x.png`}
              photoSize="small"
            >
              <Title tag="h3">For Developers</Title>
              <Description>
                A flexible API, along with official SDKs Dragonchain allows you to easily read and write to
                your own blockchain.
              </Description>
              <Button type="link" to="/developers">
                Learn More
              </Button>
            </Third>
            <Third photo={`${IMAGES_URI}/illustrations/call-to-action-blockchain@2x.png`} photoSize="small">
              <Title tag="h3">Ready to start?</Title>
              <Description>
                Create your free account to explore what’s inside the user friendly console and spin-up your
                own node in minutes.
              </Description>
              <Button blank to={ACCOUNT_URI}>
                Get Started
              </Button>
            </Third>
          </Block>

          <Block center stack icon={Shop} iconColor="white">
            <Title size="large" color="blue-light" weight="300">
              Looking for official Dragonchain merch?
            </Title>
            <p>
              Checkout our new merch store where you can find all the latest shirts, hoodies, mugs, and more.
            </p>
            <Button color="blue" hover="white" blank to="https://dragonchain.store/">
              Buy Merch
            </Button>
          </Block>
          {/* 
          <Block type="full">
            <div className="typeform-holder">
              <div
                className="typeform-widget"
                data-url="https://form.typeform.com/to/eVIFyiAa"
                data-transparency="100"
                data-hide-headers
                style={{ width: ' 100%', height: '500px' }}
              />
            </div>
          </Block> */}
        </Layout>
        <Layout dark>
          <Block type="halves" className="halves full">
            <Half center stack className="half stack clouds has-illustration" style={halfCloudStyles}>
              <div className="illustration">
                <img src={`${IMAGES_URI}/illustrations/call-to-action-exchange-a@2x.png`} alt="Dragonchain" />
              </div>
              <Title>Where to get DRGN Micro-Licenses?</Title>
              <div className="count-entry">Buy DRGN Micro-License</div>
              <p>
                Use the platform and join the revolution. Buy DRGN Micro-Licenses on popular exchanges such as Beaxy, Bittrex Global, and
                KuCoin.
              </p>
              <Button
                color="blue"
                hover="white"
                blank
                to="https://r.beaxy.com?linkid=register_media_en_dragonchain_bx%26utm_source=dragonchain%26utm_medium=affiliate%26utm_campaign=Dragonchain%26utm_content=%26sky=%26utm_term=other"
              >
                Beaxy
              </Button>
              <Button color="blue" hover="white" blank to="https://global.bittrex.com/">
                Bittrex Global
              </Button>
              <Button color="blue" hover="white" blank to="https://www.kucoin.com/">
                KuCoin
              </Button>
            </Half>
            <Half center stack className="half stack dark has-illustration" style={halfCloudDarkStyles}>
              <div className="illustration">
                <img src={`${IMAGES_URI}/illustrations/call-to-action-contest-c@2x.png`} alt="Dragonchain" />
              </div>
              <Title>Provably Fair Contest System</Title>
              <div className="count-entry">500,000 DRGN</div>
              <p>
                See our Provably Fair technology in action and join us every week for Super Happy Dragon Lucky.
              </p>
              <Button type="link" to="/contests/open-source-money">
                Learn More
              </Button>
            </Half>
          </Block>
        </Layout>
      </PageLayout>
      <ReactTypeformEmbed
        style={{ zIndex: '-999' }}
        ref={contactForm}
        popup
        url="https://form.typeform.com/to/eVIFyiAa"
      />
    </>
  );
};

export default OpenSourceMoney;
